import {useCallback, useState} from 'react';
import {useWeb3React} from '@web3-react/core';
import { useUsdtContract, useGeccoCoinContract } from './useCommonContract'
export const useBalanceOfToken = (tokenContract: any): {balance: string, balanceLoading: boolean, updateBalance: ()=>void} => {

  const {account, active} = useWeb3React();

  const [balance, setBalance] = useState<string>('');
  const [balanceLoading, setBalanceLoading] = useState(true)

  const updateBalance = useCallback(async () => {
    if (active) {
      setBalanceLoading(true)
      const b = await tokenContract.methods.balanceOf(account).call();
      setBalance(b)
      setBalanceLoading(false)
    }

  }, [tokenContract, account, active])

  return {balance, balanceLoading, updateBalance}
};

export const useBalanceOfUsdt = () => {
  const contact = useUsdtContract()
  return useBalanceOfToken(contact)
};

export const useBalanceOfGecco = () => {
  const contract = useGeccoCoinContract()
  return useBalanceOfToken(contract)
}