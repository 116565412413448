import React, { ChangeEvent } from 'react'
import {CustomInput} from './styled'
interface InputProps {
  value: string
  onChange: (newValue: string) => void
  placeholder: string
  type: 'number' | 'text'
}
const Input = (props: InputProps) => {
  const {value, placeholder, onChange, type} = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <CustomInput
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      type={type}
    />
  )
}

export default Input