import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 52px;
  background: ${p => p.theme.color.background.mainLight};
  border-top: 1px solid ${p => p.theme.color.background.border.main};
  
  @media screen and (max-width: 860px) {
    flex-direction: column;
    padding: 20px;
    gap: 30px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
`
export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px
`
export const Link = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;;
`