import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 340px;
  background-color: ${p => p.theme.color.background.secondary};
  border-radius: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  padding: 24px;
`
export const WalletsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const WalletItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 50%;
  box-sizing: border-box;
  cursor: pointer;
`
export const WalletIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
`