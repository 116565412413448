import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 8px;
  width: 340px;
  
  @media screen and (max-width: 860px) {
    width: 100%;
    max-width: 300px;
  }
`
export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: ${p => p.theme.color.background.main};
  padding: 12px;
`
export const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const CopyIconWrapper = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

