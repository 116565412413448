import React from 'react'
import { Container, LinksWrapper, Link, TextWrapper } from './styled'
import Twitter from 'Assets/static/Twitter.svg'
import Telegram from 'Assets/static/Telegram.svg'
import Email from 'Assets/static/Email.svg'
import Medium from 'Assets/static/Medium.svg'
import GitHub from 'Assets/static/GitHub.svg'
import YouTube from 'Assets/static/Youtube.svg'
import Text from 'Components/UiKit/Text'
import { css } from 'styled-components'
const Footer = () => {
  return (
    <Container>
      <TextWrapper>
        <Text size={14} weight={400} color={'#889196'}>© Gecco.co.in Ltd., 2023. All rights reserved.</Text>
        <Text size={14} weight={400} styledFragment={css`
          padding: 0 20px;
          border-right: 1px solid #889196;
          border-left: 1px solid #889196;
        `}>Terms & conditions</Text>
        <Text size={14} weight={400}>Privacy</Text>
      </TextWrapper>
      <LinksWrapper>
        <Link src={YouTube} />
        <Link src={Twitter} />
        <Link src={Telegram} />
        <Link src={Medium} />
        <Link src={GitHub} />
        <Link src={Email} />
      </LinksWrapper>
    </Container>
  )
}

export default Footer