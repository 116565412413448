import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  background: ${p => p.theme.color.background.secondary};
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 940px) {
    padding: 24px;
  }
  
  @media screen and (max-width: 860px) {
    padding: 20px 12px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`
export const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${p => p.theme.color.background.border.main};

  @media screen and (max-width: 940px) {
    padding: 16px;
  }
`
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  @media screen and (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
`
export const GeccoCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  background: ${p => p.theme.color.background.lightGreen};
  padding: 2px 10px 2px 8px;
  border-radius: 16px;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  
  @media screen and (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33%;
  gap: 12px;
  padding: 16px 24px;
  box-sizing: border-box;

  &:nth-child(1) {
    border-right: 1px solid ${p => p.theme.color.background.border.main};
  }

  @media screen and (max-width: 940px) {
    &:nth-child(1) {
      border-right: none;
    }
  }
  
  @media screen and (max-width: 860px) {
    width: 100%;
    padding: 16px;
  }
`
export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`
export const LockTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`