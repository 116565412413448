import styled from 'styled-components'

export const CustomSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  background: ${p =>p.theme.color.background.main};
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  cursor: pointer;
`