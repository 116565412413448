import styled, { keyframes } from 'styled-components'

const modalContainerAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const modalAppear = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${modalContainerAppear} 0.3s ease-in-out;
`;
export const ModalContent = styled.div`
  background-color: ${p => p.theme.color.background.secondary};
  border-radius: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  animation: ${modalAppear} 0.3s ease-in-out;
  padding: 24px;
  
  @media screen and (max-width: 860px) {
    width: 100%;
    max-width: 300px;
    padding: 16px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const IconWrapper = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`