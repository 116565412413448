import { createContext } from 'react'
interface UserType {
  refLink: string | null
  isUserHaveStakings: boolean | undefined
  setIsUserHaveStakings: (newValue: boolean) => void
  isUserAccountExist: boolean | undefined
  setIsUserAccountExist: (newValue: boolean) => void
}
export const UserContext = createContext<UserType>({
  refLink: null,
  isUserHaveStakings: undefined,
  setIsUserHaveStakings: () => null,
  isUserAccountExist: undefined,
  setIsUserAccountExist: () => null
})