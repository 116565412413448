const truncate = (str: string) => {
  return str.length > 0
    ? str.substr(0, 5) + '...' + str.substr(str.length - 4, str.length - 1)
    : str;
}
const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export {
  truncate, copyTextToClipboard
}