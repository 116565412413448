import { AxiosInstance, AxiosResponse } from 'axios'
import instance from 'API/instance'
import {UserStats} from './types'

function globalModule(instance: AxiosInstance) {
  return {
    checkUserStats(wallet: string): Promise<AxiosResponse<UserStats>> {
      return instance.post('userStats', {wallet})
    },
  }
}

export const globalRequests = Object.assign(instance, globalModule(instance))
