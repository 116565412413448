import { AxiosInstance, AxiosResponse } from 'axios'
import instance from 'API/instance'
import { CheckRefResponse, CreateUserResponse } from '../types'

function firstLoginModule(instance: AxiosInstance) {
  return {
    createUser(wallet: string, txId: string): Promise<AxiosResponse<CreateUserResponse>> {
      return instance.post('createUser', {wallet, txid: txId})
    },
    checkReferral(id: string): Promise<AxiosResponse<CheckRefResponse>> {
      return instance.post('checkReferral', {id})
    },
  }
}

export const firstLoginRequests = Object.assign(instance, firstLoginModule(instance))
