import React, { ReactNode } from 'react'
import { createGlobalStyle, DefaultTheme, ThemeProvider } from 'styled-components'
import { ThemeFragment, theme, primaryThemeFragment } from 'Stylesheets/styledVars'
import Header from 'Components/Header'
import Footer from '../Components/Footer'
import { ToastContainer } from 'react-toastify'

interface LayoutProps {
  children: ReactNode | ReactNode[]
}

const GlobalStyle = createGlobalStyle<{
  style: DefaultTheme
  themeFragment: ThemeFragment
}>`
  body {
    background: var(--bg-color);
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  :root {
    --bg-color: ${({ themeFragment }) => themeFragment.bgColorPrimary};
  }
`
const Layout = (props: LayoutProps) => {
  const { children } = props
  return (
    <>
      <ToastContainer />
      <GlobalStyle style={theme} themeFragment={primaryThemeFragment} />
      <ThemeProvider theme={Object.assign(theme, primaryThemeFragment)}>
        <Header />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
