const USDT_TO_GECCO = 1

const getStakingBody = (amount: number) => {
  return amount * USDT_TO_GECCO
}

const getCurrentGeccoPrice = (usdt: number) => {
  return usdt / USDT_TO_GECCO
}

const calculateEstimated = (amount: number) => {
  return amount / USDT_TO_GECCO
}

const calculateAnnualProfit = (amount: number) => {
  const annualInterestRate = 0.9;
  return amount * annualInterestRate;
}

const calculateTotalStaking = (amount: number) => {
  return calculateAnnualProfit(amount) + amount
}

export {
  getCurrentGeccoPrice, getStakingBody, calculateTotalStaking, calculateAnnualProfit, calculateEstimated
}