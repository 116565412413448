import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

const primaryStyledButton = (isDisabled?: boolean) => css`
  color: ${({ theme }) => theme.color.text.white};
  ${() =>
          isDisabled
                  ? css`
                    opacity: .6;
                    color: ${({ theme }) => theme.color.text.main};
                    background-color: ${({ theme }) => theme.color.background.button.main};
                    pointer-events: none;
                  `
                  : css`
                    color: ${({ theme }) => theme.color.text.main};
                    background-color: ${({ theme }) => theme.color.background.button.main};
                  `}
`

const secondaryStyledButton = (isDisabled?: boolean) =>
  isDisabled
    ? css`
            pointer-events: none;
            border: 1px solid ${p => p.theme.color.background.border.disabledSecondary};
            color: ${p => p.theme.color.text.buttonSecondaryDisabled};
            background: ${p => p.theme.color.background.secondary};
    `
    : css`
            color: ${p => p.theme.color.text.secondary};
            background-color: ${p => p.theme.color.background.secondary};
            border: 1px solid ${({ theme }) => theme.color.background.border.main};
    `

const StyledButton = styled.button<{
  buttonStyle?: 'secondary' | 'main'
  isDisabled?: boolean
  styledFragment?: FlattenSimpleInterpolation
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: max-content;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  outline: none !important;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  transition: all .3s;

  ${({ buttonStyle, isDisabled }) => {
    switch (buttonStyle) {
      case 'main':
        return primaryStyledButton(isDisabled)
      case 'secondary':
        return secondaryStyledButton(isDisabled)
      default:
        return primaryStyledButton(isDisabled)
    }
  }}

  ${({ styledFragment }) => styledFragment};
`

export default StyledButton
