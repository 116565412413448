import React from 'react'
import { Wrapper, PricingWrapper, PricingItem } from './styled'
import Text from 'Components/UiKit/Text'
import { css, useTheme } from 'styled-components'
import Button from 'Components/UiKit/Button'

const WithdrawBalanceContent = () => {
  const theme = useTheme()
  return (
    <Wrapper>
      <PricingWrapper>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Available Airdrop Balance:</Text>
          <Text size={16} weight={500}>5,385.00 USDT</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Gas Fee:</Text>
          <Text size={16} weight={400}>0.00002 BNB</Text>
        </PricingItem>
      </PricingWrapper>
      <Button
        styledFragment={css`
          width: 100%;
        `}
      >Get Airdrop</Button>
    </Wrapper>
  )
}

export default WithdrawBalanceContent