import React, { useContext, useEffect, useState } from 'react'
import {
  StakingAmount,
  StakingBlockItem,
  Wrapper,
  PricingWrapper,
  PricingItem,
  ButtonWrapper,
  RefLinkWrapper,
} from './styled'
import Text from 'Components/UiKit/Text'
import { css, useTheme } from 'styled-components'
import Select from 'Components/UiKit/Select'
import Button from 'Components/UiKit/Button'
import { UserContext } from 'Context/User'
import useValidatedState, { validationFuncs } from 'Hooks/useValidatedState'
import Input from 'Components/UiKit/Input'
import { useWeb3React } from '@web3-react/core'
import { useGeccoObtainContract, useUsdtContract, useWeb3 } from 'Hooks/useCommonContract'
import { getGeccoObtainContractAddress } from 'Utils/getAddresses'
import fromExponential from 'from-exponential'
import BigNumber from 'bignumber.js'
import { useBalanceOfUsdt } from 'Hooks/useTokenBalance'
import Loader from '../../../../Components/UiKit/Loader'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  calculateTotalStaking,
  calculateAnnualProfit,
  getStakingBody,
  getCurrentGeccoPrice,
  calculateEstimated,
} from 'Utils/constants'
import { firstLoginRequests } from '../../API/requests'

interface StakingLoginProps {
  onActiveButtonClick: () => void
}

const ALLOWANCE = 10 ** 10 * 10 ** 18
const StakingLogin = (props: StakingLoginProps) => {
  const { onActiveButtonClick } = props
  const theme = useTheme()
  const { setIsUserHaveStakings, setIsUserAccountExist } = useContext(UserContext)
  const { account } = useWeb3React()
  const web3 = useWeb3()

  const usdtContract = useUsdtContract()
  const geccoObtainContract = useGeccoObtainContract()
  const { balance, updateBalance } = useBalanceOfUsdt()

  const [[userRefLink, setRefLink], isRefValid] = useValidatedState('' as string, validationFuncs.hasValue)
  const [[stakingAmount, setStakingAmount], isStakingAmountValid] = useValidatedState('' as string, validationFuncs.hasValue)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [allowance, setAllowance] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRefCorrect, setIsRefCorrect] = useState<boolean | null>(null);
  const getAllowance = async (): Promise<string> => {
    return await usdtContract
      .methods
      .allowance(account, getGeccoObtainContractAddress())
      .call()
  }
  const updateAllowance = async () => {
    if (!account) return
    const newAllowance = await getAllowance()
    setAllowance(newAllowance)
  }
  const approve = async () => {
    setIsLoading(true)
    const amount2eth = fromExponential(ALLOWANCE)
    const gasPrice = await web3.eth.getGasPrice()
    try {
      await usdtContract.methods.approve(getGeccoObtainContractAddress(), amount2eth)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', () => {
          toast.success('Approve success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
          })
          updateAllowance()
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const createUser = async (txId: string) => {
    if (!account) return
    try {
      const response = await firstLoginRequests.createUser(account, txId)
      toast.success('Staking Success', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: 'light',
      })
      setIsUserAccountExist(response.data.success)
      setIsUserHaveStakings(response.data.success)
    } catch (e) {
      console.log(e)
    }
  }
  const stakeGeccoCoin = async () => {
    setIsLoading(true)
    const stakeAmount = (new BigNumber(10).pow(18).multipliedBy(+stakingAmount)).toString()
    const gasPrice = await web3.eth.getGasPrice()

    try {
      await geccoObtainContract.methods.obtainAndStake(stakeAmount, account, userRefLink)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', (receipt: any) => {
          const txId = receipt.transactionHash;
          createUser(txId)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const checkReferral = async (userRefLink: string) => {
    try {
      const response = await firstLoginRequests.checkReferral(userRefLink)
      setIsRefCorrect(response.data.success)
    } catch (e) {
      console.log(e)
    }
  }
  const handleRefLinkChange = (newValue: string) => {
    setRefLink(newValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      checkReferral(newValue);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    updateAllowance()
    updateBalance()
  }, [account])

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const isApprovalRequired = parseInt(allowance) === 0

  const isDisabled =
    Boolean(!(isRefCorrect && userRefLink) || +stakingAmount <= 0) ||
    Boolean(account && isApprovalRequired && +balance <= 0) ||
    Boolean(account && !isApprovalRequired && +stakingAmount <= 0 && isRefValid && +balance <= 0)

  return (
    <Wrapper>
      <Text size={20} weight={500}>Stake and get 30% APR</Text>
      <Text
        size={14}
        weight={400}
        color={theme.color.text.secondary}
      >
        Minimum staking period – 90 days
      </Text>
      <StakingAmount>
        <StakingBlockItem>
          <Text size={14} weight={400}>Staking amount</Text>
          <Text
            size={14}
            weight={400}
          >
            Balance: <Text size={14} weight={400}>
            {
              !account ?
                '--.-'
                : +balance <= 0 ? 0 : (+balance / 10 ** 18).toFixed(2)
            }
          </Text>
          </Text>
        </StakingBlockItem>
        <StakingBlockItem>
          <Input
            value={stakingAmount}
            onChange={setStakingAmount}
            placeholder={'0'}
            type={'number'}
          />
          <Select />
        </StakingBlockItem>
        <StakingBlockItem>
          <Text size={14} weight={400}>= {stakingAmount || 0} $ ({getStakingBody(+stakingAmount) || 0} Gecco)</Text>
        </StakingBlockItem>
      </StakingAmount>
      <PricingWrapper>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Staking body</Text>
          <Text size={16} weight={400}
                color={theme.color.text.secondary}>{getStakingBody(+stakingAmount) || 0} Gecco</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Current Gecco price</Text>
          <Text size={16} weight={400} color={theme.color.text.secondary}>{getCurrentGeccoPrice(1)} $</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Estimated total for staking</Text>
          <Text size={16} weight={400}
                color={theme.color.text.secondary}>{calculateEstimated(+stakingAmount) || 0} USDT</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Staking Rewards for 90 days</Text>
          <Text size={16} weight={400}
                color={theme.color.text.green}>{calculateAnnualProfit(+stakingAmount) || 0} USDT</Text>
        </PricingItem>
      </PricingWrapper>
      <PricingItem>
        <Text size={16} weight={500}>Total *</Text>
        <Text size={16} weight={500}>{calculateTotalStaking(+stakingAmount) || 0} USDT</Text>
      </PricingItem>
      <RefLinkWrapper isValid={isRefCorrect === null ? true : isRefCorrect}>
        <Text
          size={14}
          weight={400}
          color={isRefCorrect === null || isRefCorrect ? theme.color.text.main : theme.color.text.error}
        >
          {isRefCorrect === null || isRefCorrect ? 'Enter your inviter ID' : 'This Inviter ID is non-existent'}
        </Text>
        <Input
          value={userRefLink}
          onChange={handleRefLinkChange}
          placeholder={'100000001'}
          type={'text'}
        />
      </RefLinkWrapper>
      <ButtonWrapper>
        <Button
          isDisabled={isDisabled}
          onClick={account ? (isApprovalRequired ? approve : stakeGeccoCoin) : onActiveButtonClick}
          styledFragment={css`
            width: 100%;
          `}
        >
          {isLoading ?
            <Loader size={15} color={theme.color.text.main} />
            :
            <>{account ? (isApprovalRequired ? 'Approve' : 'Stake Now') : 'Connect Wallet'}</>
          }
        </Button>
        <Text size={12} weight={400} color={theme.color.text.secondary}>
          * All figures are estimates provided for your convenience only, and by no means represent guaranteed returns
        </Text>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default StakingLogin