export const switchNetwork = async () => {
  try {
    if (window.ethereum) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
    } else {
      console.error('Ethereum is not available on the window object.');
    }
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        if (window.ethereum) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x38',
                rpcUrls: ['https://bsc-dataseed.binance.org/'],
                chainName: 'BSC Mainnet',
                nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                blockExplorerUrls: ['https://bscscan.com/'],
              },
            ],
          });
        } else {
          console.error('Ethereum is not available on the window object.');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
