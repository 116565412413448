import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './Connectors'
import './index.css'
import './Fonts/Trap-ExtraBold.otf'
import './Fonts/Trap-Black.otf'
import './Fonts/Trap-Light.otf'
import './Fonts/Trap-Medium.otf'
import './Fonts/Trap-Regular.otf'
import './Fonts/Trap-Bold.otf'
import './Fonts/Trap-SemiBold.otf'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </React.StrictMode>,
)

reportWebVitals()
