import React, { useContext, useEffect } from 'react'
import { Wrapper, WalletItem, WalletIcon, WalletsWrapper } from './styled'
import Text from 'Components/UiKit/Text'
import { useWeb3React } from '@web3-react/core'
import { switchNetwork } from 'Utils/switchNetwork'
import { injected, walletconnect } from 'Connectors'
import MetamaskIcon from 'Assets/static/MetamaskIcon.png'

interface WalletConnectProps {
  onWalletConnect: () => void
}

const WalletConnect = (props: WalletConnectProps) => {
  const { onWalletConnect } = props
  const { chainId, activate, active, error, account } = useWeb3React()
  const connectMetamask = () => {
    activate(injected).then(() => {
      onWalletConnect()
    })
  }
  const connectWalletConnect = () => {
    activate(walletconnect).then(() => {
      window.location.reload()
    })
  }

  useEffect(() => {
    const initNetwork = async () => {
      if (56 !== chainId) {
        await switchNetwork()
      }
    }
    initNetwork()
  }, [active, chainId, error])

  return (
    <Wrapper>
      <Text size={20} weight={500}>Connect Account</Text>
      <Text size={14} weight={400}>Choose one of the convenient ways to connect your wallet</Text>
      <WalletsWrapper>
        <WalletItem onClick={connectMetamask}>
          <WalletIcon src={MetamaskIcon} />
          <Text size={16} weight={600}>Metamask</Text>
        </WalletItem>
        {/* <WalletItem onClick={connectWalletConnect}> */}
        {/*   <WalletIcon src={WalletconnectIcon} /> */}
        {/*   <Text size={16} weight={600}>Wallet Connect</Text> */}
        {/* </WalletItem> */}
      </WalletsWrapper>
    </Wrapper>
  )
}

export default WalletConnect