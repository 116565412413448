import React, { useContext, useState } from 'react'
import {
  Container,
  TotalAccountRewards,
  TextWrapper,
  TablesWrapper,
} from './styled'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Button'
import styled, { css } from 'styled-components'

import PersonalPercent from './Components/PersonalPercent'
import Staking from './Components/Staking'
import Table from 'Components/UiKit/Table'
import { UserContext } from 'Context/User'
import { useWeb3React } from '@web3-react/core'
import FirstLogin from '../FirstLogin'
import Loader from '../../Components/UiKit/Loader'

const headers = ['Referees', 'Referral turnover (USDT)', 'Action']

const mockRows = [
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
  ['48,195', '513,582', ''],
]

const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 68px - 69px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainDashboard = () => {
  const { isUserHaveStakings, isUserAccountExist } = useContext(UserContext)
  const { account } = useWeb3React()

  const isDashboardShown = account && isUserHaveStakings && isUserAccountExist

  return (
    <Container>
      {isUserAccountExist !== undefined && isUserHaveStakings !== undefined ?
        <>
          {!isDashboardShown && <FirstLogin />}
          <TotalAccountRewards>
            <TextWrapper>
              <Text size={16} weight={500}>Total Account Rewards • USDT</Text>
              <Text size={44} weight={700}>13,085.05</Text>
            </TextWrapper>
            <Button styledFragment={css`
          width: 416px;

          @media screen and (max-width: 940px) {
            width: 320px;
          }

          @media screen and (max-width: 860px) {
            width: 100%;
          }
        `}
            >
              Get It All
            </Button>
          </TotalAccountRewards>
          <PersonalPercent />
          <Staking />
          <TablesWrapper>
            <Table
              showRowNumbers
              tableTitle={'Transactions history'}
              headers={headers}
              rows={mockRows}
            />
            <Table
              showRowNumbers
              tableTitle={'Transactions history'}
              headers={headers}
              rows={mockRows}
            />
          </TablesWrapper>
        </>
        :
        <LoaderWrapper>
          <Loader size={25} color={'#000'} />
        </LoaderWrapper>
      }
    </Container>
  )
}

export default MainDashboard