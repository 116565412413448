import styled from 'styled-components';
export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 32px;
  background: ${p => p.theme.color.background.secondary};
  width: 100%;
  box-sizing: border-box;
  
  @media screen and (max-width: 940px) {
    padding: 12px 16px;
    background: ${p => p.theme.color.background.main};
    border-bottom: 1px solid ${p => p.theme.color.background.border.main};
  }
`
export const Logo = styled.img`
	width: 186px;
	height: 28px;
	cursor: pointer;
`
export const MobileLogo = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
`
export const HeaderItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
`
export const HeaderItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;
`
export const HeaderItemIcon = styled.img`
	width: 24px;
  height: 24px;
`
export const ConnectionCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${p => p.theme.color.background.green};
`
export const MobileMeniSticks = styled.div`
  padding: 8px;
  background: ${p => p.theme.color.background.secondary};
  border-radius: 8px;
`
export const MenuSticksIcon = styled.div`
  width: 24px;
  height: 24px;
`