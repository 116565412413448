export const getGeccoCoinAddress = () => {
  return '0xD5fB20a8f43D4F547Db6aF2df2927c0E8580A30D'
}
export const getStakingGeccoContractAddress = () => {
  return '0xdF48e2f0b10279398dB3a303f93e3F54B63A48fD'
}
export const getGeccoObtainContractAddress = () => {
  return '0xBBFaB10a80462e131102a04a25c5b4b2AC13aFa2'
}
export const getUsdtContractAddress = () => {
  return '0x55d398326f99059fF775485246999027B3197955'
}