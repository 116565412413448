import React from 'react'

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
      <path d="M13 1L7 7L1 1" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default Arrow