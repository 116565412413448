import React, { useEffect, useState } from 'react'
import './App.css'
import Layout from 'Layout'
import MainDashboard from 'Widgets/MainDashboard'
import { injected } from './Connectors'
import { useWeb3React } from '@web3-react/core'
import { UserContext } from './Context/User'
import { useGeccoStakingContract } from './Hooks/useCommonContract'
import { globalRequests } from 'API/globalRequests'
import { useConnectionCheck } from './Hooks/useConnectionCheck'
const App = () => {
  const { active, activate, account } = useWeb3React()
  const geccoStakingContract = useGeccoStakingContract()

  const [isUserHaveStakings, setIsUserHaveStakings] = useState<boolean | undefined>(undefined)
  const [isUserAccountExist, setIsUserAccountExist] = useState<boolean | undefined>(undefined)
  const [refLink, setRefLink] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getUserStats = async () => {
    if (!account) return
    setIsLoading(true)
    try {
      const response = await globalRequests.checkUserStats(account)
      setIsUserAccountExist(response.data.success)

      if (response.data.success) {
        setRefLink(response.data.stats.inviteInfo.yourRefCode)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const getUserStakings = async () => {
    if (!account) return
    try {
      const response = await geccoStakingContract.methods.checkStake(account).call()
      setIsUserHaveStakings(response)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized && !active) {
        activate(injected)
      }
    })
  }, [activate])

  useEffect(() => {
    getUserStakings()
    getUserStats()
  }, [account])
  console.log(isUserHaveStakings, isUserAccountExist)
  useConnectionCheck()

  return (
    <UserContext.Provider
      value={{ refLink, isUserHaveStakings, setIsUserHaveStakings, isUserAccountExist, setIsUserAccountExist }}>
      <Layout>
        <MainDashboard />
      </Layout>
    </UserContext.Provider>
  )
}

export default App
