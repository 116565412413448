import React, { useEffect, useState } from 'react'
import { Wrapper, FieldsWrapper, Field, CopyLinkWrapper, CopyIconWrapper } from './styled'
import Text from 'Components/UiKit/Text'
import CopyIcon from 'Assets/static/Copy.svg'
import { css, useTheme } from 'styled-components'
import Button from 'Components/UiKit/Button'
import { copyTextToClipboard } from 'Utils/common'
import { UserStats } from '../../../../API/types'
import { globalRequests } from '../../../../API/globalRequests'
import { useWeb3React } from '@web3-react/core'

const ShareModalContent = () => {
  const theme = useTheme()
  const {account} = useWeb3React()

  const [userStats, setUserStats] = useState<UserStats |undefined>(undefined)

  const getUserStats = async () => {
    if (!account) return
    try {
      const response = await globalRequests.checkUserStats(account)
      setUserStats(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getUserStats()
  }, [account])

  return (
    <Wrapper>
      <Text size={14} weight={400}>ID who invited you: <strong>1000000071</strong></Text>
      <FieldsWrapper>
        <Field>
          <Text size={17} weight={400} color={theme.color.text.secondary}>Your Inviter ID is:</Text>
          <CopyLinkWrapper>
            <Text size={18} weight={500}>{userStats?.stats.inviteInfo.yourInviter}</Text>
            <CopyIconWrapper
              src={CopyIcon}
              onClick={() => copyTextToClipboard('GA395')}
            />
          </CopyLinkWrapper>
        </Field>
        <Field>
          <Text size={17} weight={400} color={theme.color.text.secondary}>Staking referral link</Text>
          <CopyLinkWrapper>
            <Text size={18} weight={500}>{userStats?.stats.inviteInfo.yourRefCode}</Text>
            <CopyIconWrapper
              src={CopyIcon}
              onClick={() => copyTextToClipboard('gecco.co.in/ref=GA395')}
            />
          </CopyLinkWrapper>
        </Field>
      </FieldsWrapper>
      <Button
        onClick={() => copyTextToClipboard('gecco.co.in/ref=GA395')}
        styledFragment={css`
          width: 100%;
        `}
      >Copy Link</Button>
    </Wrapper>
  )
}

export default ShareModalContent