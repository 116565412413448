import React from 'react';
import { useTheme } from 'styled-components'
import { Wrapper, Circle } from './styled'

interface LoaderProps {
  animationDuration?: number,
  size: number,
  color: string
}

const Loader = (props: LoaderProps) => {
  const theme = useTheme()
  
  const {
    animationDuration = 1000, 
    color = theme.color.background.main, 
    size = 40
  } = props
  
  return (
    <Wrapper height={size} width={size}>
      <Circle
        className="circle-1"
        style={{
          borderWidth: `${size / 10}px`,
          animationDuration: `${animationDuration}ms`,
          borderTopColor: color,
        }}
      />
      <Circle
        className="circle-2"
        style={{
          borderWidth: `${size / 10}px`,
          animationDuration: `${animationDuration}ms`,
          borderBottomColor: color,
        }}
      />
    </Wrapper>
  );
};
export default Loader;
