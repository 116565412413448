import styled from 'styled-components'
export const CustomInput = styled.input`
  font-size: 20px;
  font-weight: 600;
  border: none;
  background: none;
  width: 100%;
  box-sizing: border-box;
  
  &:focus,
  &:active {
    border: none !important;
    outline: none !important;
  }
  
  &::placeholder {
    color: rgba(0, 0, 0, .3)
  }
`