import React, { ReactNode, useRef } from 'react'
import { ModalContent, ModalBackdrop, ModalTitle, IconWrapper } from './styled'
import { useOnClickOutside } from 'Hooks/useClickOutside'
import Text from '../Text'
import CloseIcon from 'Assets/static/Close.svg'
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string
  isBlurOverlay?: boolean
}

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    children,
    title,
  } = props
  const contentRef = useRef<HTMLDivElement>(null)
  const handleCLose = () => {
    onClose()
  }

  useOnClickOutside(contentRef, handleCLose)

  if (!isOpen) {
    return null
  }

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent ref={contentRef} onClick={(e) => e.stopPropagation()}>
        <ModalTitle>
          <Text size={20} weight={500}>{title}</Text>
         <IconWrapper src={CloseIcon} onClick={onClose}/>
        </ModalTitle>
        {children}
      </ModalContent>
    </ModalBackdrop>
  )
}

export default Modal
