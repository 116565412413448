import React, { useContext, useEffect, useState } from 'react'
import {
  StakingAmount,
  StakingBlockItem,
  Wrapper,
  PricingWrapper,
  PricingItem,
  ButtonWrapper,
} from './styled'
import Text from 'Components/UiKit/Text'
import { css, useTheme } from 'styled-components'
import Select from 'Components/UiKit/Select'
import Button from 'Components/UiKit/Button'
import useValidatedState, {validationFuncs} from 'Hooks/useValidatedState'
import Input from 'Components/UiKit/Input'
import {
  calculateAnnualProfit,
  calculateEstimated, calculateTotalStaking,
  getCurrentGeccoPrice,
  getStakingBody,
} from 'Utils/constants'
import { useWeb3React } from '@web3-react/core'
import { useGeccoObtainContract, useUsdtContract, useWeb3 } from 'Hooks/useCommonContract'
import { useBalanceOfUsdt } from 'Hooks/useTokenBalance'
import { getGeccoObtainContractAddress } from 'Utils/getAddresses'
import fromExponential from 'from-exponential'
import { toast } from 'react-toastify'
import Loader from 'Components/UiKit/Loader'
import BigNumber from 'bignumber.js'
import { UserContext } from '../../../../Context/User'

interface StakingModalContentProps {
  onStakingFinish: () => void
}

const ALLOWANCE = 10 ** 10 * 10 ** 18

const StakingModalContent = (props: StakingModalContentProps) => {
  const { onStakingFinish } = props

  const theme = useTheme()
  const {account} = useWeb3React()
  const web3 = useWeb3()
  const usdtContract = useUsdtContract()
  const geccoObtainContract = useGeccoObtainContract()
  const { balance, updateBalance } = useBalanceOfUsdt()
  const {refLink} = useContext(UserContext)

  const [[stakingAmount, setStakingAmount], isStakingAmountValid] = useValidatedState('' as string, validationFuncs.hasValue)
  const [allowance, setAllowance] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getAllowance = async (): Promise<string> => {
    return await usdtContract
      .methods
      .allowance(account, getGeccoObtainContractAddress())
      .call()
  }
  const updateAllowance = async () => {
    if (!account) return
    const newAllowance = await getAllowance()
    setAllowance(newAllowance)
  }
  const approve = async () => {
    setIsLoading(true)
    const amount2eth = fromExponential(ALLOWANCE)
    const gasPrice = await web3.eth.getGasPrice()
    try {
      await usdtContract.methods.approve(getGeccoObtainContractAddress(), amount2eth)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', () => {
          toast.success('Approve success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
          })
          updateAllowance()
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const stakeGeccoCoin = async () => {
    setIsLoading(true)
    const stakeAmount = (new BigNumber(10).pow(18).multipliedBy(+stakingAmount)).toString()
    const gasPrice = await web3.eth.getGasPrice()

    try {
      await geccoObtainContract.methods.obtainAndStake(stakeAmount, account, refLink)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', () => {
          toast.success('Staking Success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
          })
          onStakingFinish()
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    updateBalance()
    updateAllowance()
  }, [account])

  const isApprovalRequired = parseInt(allowance) === 0

  const isDisabled =
    Boolean(account && isApprovalRequired && +balance >= 0) ||
    Boolean(account && !isApprovalRequired && +stakingAmount <= 0 && +balance >= 0)

  return (
    <Wrapper>
      <Text
        size={14}
        weight={400}
        color={theme.color.text.secondary}
      >
        Minimum staking period – 90 days
      </Text>
      <StakingAmount>
        <StakingBlockItem>
          <Text size={14} weight={400}>Staking amount</Text>
          <Text
            size={14}
            weight={400}
          >
            Balance: <Text size={14} weight={400}>
            {
              !account ?
                '--.-'
                : +balance <= 0 ? 0 : (+balance / 10 ** 18).toFixed(2)
            }
          </Text>
          </Text>
        </StakingBlockItem>
        <StakingBlockItem>
          <Input
            value={stakingAmount}
            onChange={setStakingAmount}
            placeholder={'0'}
            type={'number'}
          />
          <Select />
        </StakingBlockItem>
        <StakingBlockItem>
          <Text size={14} weight={400}>= {stakingAmount || 0} $ ({getStakingBody(+stakingAmount) || 0} Gecco)</Text>
        </StakingBlockItem>
      </StakingAmount>
      <PricingWrapper>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Staking body</Text>
          <Text size={16} weight={400}
                color={theme.color.text.secondary}>{getStakingBody(+stakingAmount) || 0} Gecco</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Current Gecco price</Text>
          <Text size={16} weight={400} color={theme.color.text.secondary}>{getCurrentGeccoPrice(1)} $</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Estimated total for staking</Text>
          <Text size={16} weight={400}
                color={theme.color.text.secondary}>{calculateEstimated(+stakingAmount) || 0} USDT</Text>
        </PricingItem>
        <PricingItem>
          <Text size={16} weight={400} color={theme.color.text.secondary}>Staking Rewards for 90 days</Text>
          <Text size={16} weight={400}
                color={theme.color.text.green}>{calculateAnnualProfit(+stakingAmount) || 0} USDT</Text>
        </PricingItem>
      </PricingWrapper>
      <PricingItem>
        <Text size={16} weight={500}>Total *</Text>
        <Text size={16} weight={500}>{calculateTotalStaking(+stakingAmount) || 0} USDT</Text>
      </PricingItem>
      <ButtonWrapper>
        <Button
          isDisabled={isDisabled}
          onClick={isApprovalRequired ? approve : stakeGeccoCoin}
          styledFragment={css`
            width: 100%;
          `}
        >
          {isLoading ?
            <Loader size={15} color={theme.color.text.main} />
            :
            <>{account ? (isApprovalRequired ? 'Approve' : 'Stake Now') : 'Connect Wallet'}</>
          }
        </Button>
        <Text size={12} weight={400} color={theme.color.text.secondary}>
          * All figures are estimates provided for your convenience only, and by no means represent guaranteed returns
        </Text>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default StakingModalContent