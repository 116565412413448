import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 390px;

  @media screen and (max-width: 860px) {
    width: 100%;
    max-width: 300px;
  }
`
export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
`

export const PricingItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`