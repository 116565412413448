import React from 'react';

const Share = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M16.5 6.5L16.5 1.5M16.5 1.5H11.5M16.5 1.5L9.83333 8.16667M7.33333 3.16667H5.5C4.09987 3.16667 3.3998 3.16667 2.86502 3.43915C2.39462 3.67883 2.01217 4.06129 1.77248 4.53169C1.5 5.06647 1.5 5.76654 1.5 7.16667V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H10.8333C12.2335 16.5 12.9335 16.5 13.4683 16.2275C13.9387 15.9878 14.3212 15.6054 14.5608 15.135C14.8333 14.6002 14.8333 13.9001 14.8333 12.5V10.6667"
				stroke="black" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

export default Share;