import React from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components';

type FontWeight = number | 'bold' | 'bolder' | 'lighter' | 'normal'
type ElementType = 'span' | 'h1' | 'h2' | 'div' | 'p'

interface TextProps {
  size: number | string
  weight?: FontWeight
  children: React.ReactNode
  lineHeight?: number
  font?: 'Inter' | 'Trap'
  as?: ElementType
  styledFragment?: FlattenSimpleInterpolation
  color?: string
}

const Text = styled.span.attrs<TextProps>(({ as = 'span' }) => ({
  as,
}))<TextProps>`
  color: ${props => props.color ? props.color : props.theme.color.text.main};
  font-size: ${props =>
    typeof props.size === 'number' ? `${props.size}px` : props.size};
  font-family: ${({ font = 'Inter' }) => font}, sans-serif;
  font-weight: ${({ weight = 'normal' }) => weight};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : 'normal')};

  ${({ styledFragment }) => styledFragment};
`

export default Text
