import React, { useEffect, useState } from 'react'
import {
  Card,
  CardWrapper,
  CardText,
  ContentTitle,
  ContentWrapper,
  GeccoCount,
  Wrapper,
  TitleWrapper,
  ButtonContentWrapper,
  ButtonIcon,
  LockTextWrapper,
  CardsWrapper,
} from './styled'
import Text from 'Components/UiKit/Text'
import LoaderIcon from 'Assets/icons/Loader'
import { useTheme } from 'styled-components'
import Button from 'Components/UiKit/Button'
import Modal from 'Components/UiKit/Modal'
import StakingModalContent from '../StakingModalContent'
import { useWeb3React } from '@web3-react/core'
import { useGeccoStakingContract, useUsdtContract, useWeb3 } from 'Hooks/useCommonContract'
import Loader from 'Components/UiKit/Loader'
import { toast } from 'react-toastify'
import PlusCircleIcon from 'Assets/static/PlusCircle.svg'
import { IStaking } from '../../types'
import { getGeccoObtainContractAddress, getStakingGeccoContractAddress } from '../../../../Utils/getAddresses'

const Staking = () => {
  const theme = useTheme()
  const { account } = useWeb3React()
  const web3 = useWeb3()

  const stakingContract = useGeccoStakingContract()
  const usdtContract = useUsdtContract()

  const [isStakingModalActive, setIsStakingModalActive] = useState<boolean>(false)
  const [activeStakeIds, setActiveStakeIds] = useState<number[]>([])
  const [stakings, setStakings] = useState<any[]>([])
  const [mergedStakings, setMergedStakings] = useState<IStaking[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getUserStakings = async () => {
    if (!account) return
    try {
      const response = await stakingContract.methods.getActiveStakesInfo(account).call()
      setStakings(response)
    } catch (e) {
      console.log(e)
    }
  }
  const getActiveStakeIds = async () => {
    if (!account) return
    try {
      const response = await stakingContract.methods.checkActiveId(account).call()
      setActiveStakeIds(response)
    } catch (e) {
      console.log(e)
    }
  }
  const mergeStakingsWithIds = () => {
    if (!stakings.length && !activeStakeIds.length) return
    const mergedStakings = stakings.map((stake, index) => {
      if (index < activeStakeIds.length) {
        return { ...stake, id: activeStakeIds[index] }
      }
      return stake
    })
    setMergedStakings(mergedStakings)
  }
  const withdrawRewards = async (id: string) => {
    if (!account) return
    setIsLoading(true)
    const gasPrice = await web3.eth.getGasPrice()
    try {
      await stakingContract.methods.withdrawRewards(id)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', () => {
          toast.success('Withdraw rewards success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
          })
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const withdrawStake = async (id: string) => {
    if (!account) return
    setIsLoading(true)
    const gasPrice = await web3.eth.getGasPrice()
    try {
      await stakingContract.methods.withdrawStake(id)
        .send({ from: account, gasPrice: gasPrice })
        .once('receipt', () => {
          toast.success('Withdraw stake success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
          })
        })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const closeStakingModal = () => {
    setIsStakingModalActive(false)
  }
  const onStakingFinish = () => {
    getUserStakings()
    getActiveStakeIds()
    setIsStakingModalActive(false)
  }
  const calculateRemainingDays = (finishTime: string) => {
    const finishDate = new Date(Number(finishTime) * 1000);
    const currentDate = new Date();

    const differenceInMilliseconds = finishDate.getTime() - currentDate.getTime();

    if (differenceInMilliseconds < 0) {
      return 'Unlocked';
    }

    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return `${Math.ceil(differenceInDays)} days left`
  }

  useEffect(() => {
    getActiveStakeIds()
    getUserStakings()
  }, [account])

  useEffect(() => {
    mergeStakingsWithIds()
  }, [activeStakeIds.length, stakings])

  return (
    <Wrapper>
      <TitleWrapper>
        <Text size={24} weight={700}>Staking</Text>
        <Button onClick={() => setIsStakingModalActive(true)}>
          <ButtonContentWrapper>
            <ButtonIcon src={PlusCircleIcon} />
            Add New Staking
          </ButtonContentWrapper>
        </Button>
      </TitleWrapper>
      {mergedStakings.map((staking, index) => (
        <ContentWrapper key={staking.id}>
          <ContentTitle>
            <Text size={20} weight={600}>#{index + 1} → {staking.percentage}% Rewards per 90 days</Text>
          </ContentTitle>
          <CardsWrapper>
            <CardWrapper>
              <Card>
                <CardText>
                  <LockTextWrapper>
                    <Text size={24} weight={500}>{(+staking.amount / 10 ** 18).toFixed(1)}</Text>
                    <GeccoCount>
                      {+staking.duration >= 0 && <LoaderIcon />}
                      <Text size={14} weight={500} color={theme.color.text.green}>{calculateRemainingDays(staking.duration)}</Text>
                    </GeccoCount>
                  </LockTextWrapper>
                  <Text size={14} weight={500} color={theme.color.text.secondary}>Current staking balance (Gecco)</Text>
                </CardText>
              </Card>
              <Card>
                <CardText>
                  <Text size={24} weight={500}>{(+staking.withdrawableAmount / 10 ** 18).toFixed(1)}</Text>
                  <Text size={14} weight={500} color={theme.color.text.secondary}>Ready for withdraw (Gecco)</Text>
                </CardText>
                <Button
                  isDisabled={+staking.percentage >= 0}
                  buttonStyle={'secondary'}
                  onClick={() => withdrawStake(staking.id)}
                >
                  {
                    isLoading ?
                      <Loader size={10} color={theme.color.text.main} />
                      :
                      'Withdraw Staking'
                  }
                </Button>
              </Card>
              <Card>
                <CardText>
                  <Text size={24} weight={500}>+{(+staking.currentReward / 10 ** 18).toFixed(1)}</Text>
                  <Text size={14} weight={500} color={theme.color.text.secondary}>Available rewards (USDT)</Text>
                </CardText>
                <Button buttonStyle={'secondary'} onClick={() => withdrawRewards(staking.id)}>
                  {
                    isLoading ?
                      <Loader size={10} color={theme.color.text.main} />
                      :
                      'Claim Rewards'
                  }
                </Button>
              </Card>
            </CardWrapper>
          </CardsWrapper>
        </ContentWrapper>
      ))}
      <Modal
        isOpen={isStakingModalActive}
        onClose={closeStakingModal}
        title={'Stake and get 30% APR'}
      >
        <StakingModalContent onStakingFinish={onStakingFinish} />
      </Modal>
    </Wrapper>
  )
}

export default Staking