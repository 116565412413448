import axios from 'axios'

const axiosConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
}

const instance = axios.create({
  baseURL: axiosConfig.baseUrl,
})

instance.interceptors.response.use(
  r => r,
  e => {
    console.log('Error with request')
    throw e
  },
)

export default instance
