import styled from 'styled-components'

export const Wrapper = styled.div<{height: number, width: number}>`
  transition: all 0.2s;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 100%;
  position: relative;
`

export const Circle = styled.div`
  transition: all 0.2s;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
  
  &.circle-1 {
    border-top-color: #ff1d5e;
    animation: half-circle-spinner-animation 1s infinite;
  }
  
  &.circle-2 {
    border-bottom-color: #ff1d5e;
    animation: half-circle-spinner-animation 1s infinite;
  }

  @keyframes half-circle-spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`