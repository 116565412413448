import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 390px;
  background-color: ${p => p.theme.color.background.secondary};
  border-radius: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  padding: 24px;

  @media screen and (max-width: 860px) {
    width: 100%;
    max-width: 300px;
  }
`
export const StakingAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.color.background.border.main};
`
export const StakingBlockItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${p => p.theme.color.background.border.main};
`
export const PricingItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`
export const RefLinkWrapper = styled.div<{isValid: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  transition: border .3s linear;
  border: 1px solid ${p => p.isValid ? p.theme.color.background.border.main : p.theme.color.background.border.error};
  box-sizing: border-box;
`