import React from 'react'

const Loader = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <g clipPath='url(#clip0_911_370)'>
        <path
          d='M6 1V3M6 9V11M3 6H1M11 6H9M9.53921 9.53921L8.125 8.125M9.53921 2.49997L8.125 3.91418M2.46079 9.53921L3.875 8.125M2.46079 2.49997L3.875 3.91418'
          stroke='#12B76A' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_911_370'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Loader