import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  background: ${p => p.theme.color.background.secondary};
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 940px) {
    padding: 24px;
  }

  @media screen and (max-width: 860px) {
    gap: 16px;
    padding: 20px 12px;
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  @media screen and (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
`
export const ShareButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  
  @media screen and (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 33%;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  background: ${p => p.theme.color.background.secondary};
  box-sizing: border-box;
  
  @media screen and (max-width: 940px) {
    padding: 16px;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px
`
export const TotalBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  gap: 12px;
  border-radius: 16px;
  background: ${p => p.theme.color.background.lightGreen};
  color: ${p => p.theme.color.text.green};
`
export const Balance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background: ${p => p.theme.color.background.lightGreen};
`
