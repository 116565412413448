import React, { useState } from 'react'
import {
  TableFooter,
  TableContainer,
  TableHeader, TableHeaderItem,
  TableRow, TableRowItem,
  TableTitle, FooterButtons,
} from './styled'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Button'
import { useTheme } from 'styled-components'

interface TableProps {
  tableTitle: string
  headers: string[];
  rows: string[][];
  showRowNumbers?: boolean;
}

const rowsPerPage = 10

const Table = (props: TableProps) => {
  const { headers, rows, showRowNumbers, tableTitle } = props
  const theme = useTheme()

  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(rows.length / rowsPerPage)
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <TableContainer>
      <TableTitle>
        <Text size={24} weight={700}>{tableTitle}</Text>
      </TableTitle>
      <TableHeader>
        {showRowNumbers &&
          <TableHeaderItem style={{ maxWidth: 'max-content' }}>
            <Text size={12} weight={500}>#</Text>
          </TableHeaderItem>
        }
        {headers.map((header, index) => (
          <TableHeaderItem key={index}>
            <Text size={12} weight={500}>{header}</Text>
          </TableHeaderItem>
        ))}
      </TableHeader>
      {rows
        .slice((currentPage - 1) * 10, currentPage * 10)
        .map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {showRowNumbers &&
              <TableRowItem style={{ maxWidth: 'max-content' }}>
                <Text size={14} weight={500}>{(currentPage - 1) * 10 + rowIndex + 1}</Text>
              </TableRowItem>
            }
            {row.map((cell, cellIndex) => (
              <TableRowItem key={cellIndex}>
                <Text size={14} weight={500}>{cell}</Text>
              </TableRowItem>
            ))}
          </TableRow>
        ))
      }
      <TableFooter>
        <Text size={14} weight={500} color={theme.color.text.secondary}>Pages {currentPage} of {totalPages}</Text>
        <FooterButtons>
          <Button
            buttonStyle={'secondary'}
            onClick={handlePreviousPage}
            isDisabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            buttonStyle={'secondary'}
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}
          >
            Next
          </Button>
        </FooterButtons>
      </TableFooter>
    </TableContainer>
  )
}

export default Table