import styled from 'styled-components'
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.color.background.border.main};
  width: 100%;
  overflow: hidden;
  
  @media screen and (max-width: 860px) {
    overflow-x: auto;
  }
`;
export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${p => p.theme.color.background.secondary};
  padding: 20px 24px;
  width: 100%;
  box-sizing: border-box;
`
export const TableHeader = styled.div`
  display: flex;
  width: 100%;
`;
export const TableHeaderItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  border-bottom: 1px solid ${p => p.theme.color.background.border.main};
  background: #FCFCFD;
`
export const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  background: ${p => p.theme.color.background.secondary};
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.color.background.border.main};
  
  &:nth-child(2n) {
    background: #FCFCFD;
  }
`;
export const TableRowItem = styled.div`
  flex: 1;
  padding: 16px 24px;
`
export const TableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${p => p.theme.color.background.secondary};
  width: 100%;
  box-sizing: border-box;
  padding: 12px 24px;
`;
export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`