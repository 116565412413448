import React, { useEffect, useState } from 'react'
import {
  CardsWrapper,
  ShareButtonWrapper,
  Title,
  Wrapper,
  Card,
  CardContent,
  TotalBalanceWrapper, Balance,
} from './styled'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Button'
import Share from 'Assets/icons/Share'
import { css, useTheme } from 'styled-components'
import Modal from '../../../../Components/UiKit/Modal'
import ShareModalContent from '../ShareModalContent'
import WithdrawBalanceContent from '../WithdrawBalanceContent'
import { globalRequests } from '../../../../API/globalRequests'
import { useWeb3React } from '@web3-react/core'
import { UserStats } from '../../../../API/types'

const PersonalPercent = () => {
  const theme = useTheme()
  const {account} = useWeb3React()
  const [isShareModalActive, setIsShareModalActive] = useState<boolean>(false)
  const [isAirdropModalActive, setIsAirdropModalActive] = useState<boolean>(false)
  const [userStats, setUserStats] = useState<UserStats |undefined>(undefined)

  const getUserStats = async () => {
    if (!account) return
    try {
      const response = await globalRequests.checkUserStats(account)
      setUserStats(response.data)
    } catch (e) {
      console.log(e)
    }
  }
  const closeShareModal = () => {
    setIsShareModalActive(false)
  }

  const closeAirdropModal = () => {
    setIsAirdropModalActive(false)
  }

  useEffect(() => {
    getUserStats()
  }, [account])

  return (
    <Wrapper>
      <Title>
        <Text size={24} weight={700}>Personal Percent → 6%</Text>
        <Button
          onClick={() => setIsShareModalActive(true)}
          styledFragment={css`
            @media screen and (max-width: 860px) {
              width: 100%;
            }
          `}
        >
          <ShareButtonWrapper>
            <Share />
            <Text size={14} weight={600}>Share Invite</Text>
          </ShareButtonWrapper>
        </Button>
      </Title>
      <CardsWrapper>
        <Card>
          <Text size={16} weight={500}>Rank progress • USDT</Text>
          <CardContent>
            <Text size={30} weight={700}>
              {userStats?.stats.rankProgress.currentValue}
              <Text size={20} weight={600}> of {userStats?.stats.rankProgress.targetValue}</Text>
            </Text>
            <TotalBalanceWrapper>
              <Balance>{userStats?.stats.totalAccountRewards}</Balance>
              <Text size={14} weight={500} color={theme.color.text.green}>Total accrued balance</Text>
            </TotalBalanceWrapper>
          </CardContent>
        </Card>
        <Card>
          <Text size={16} weight={500}>Airdrop • USDT</Text>
          <CardContent>
            <Text size={24} weight={500}>{userStats?.stats.airdropBalance}</Text>
            <Button onClick={() => setIsAirdropModalActive(true)} buttonStyle={'secondary'}>Withdraw Balance</Button>
          </CardContent>
        </Card>
        <Card>
          <Text size={16} weight={500}>Invite Bonus {userStats?.stats.inviteBonus.percent}% • USDT</Text>
          <CardContent>
            <Text size={24} weight={500}>{userStats?.stats.inviteBonus.value}</Text>
            <Button buttonStyle={'secondary'}>Withdraw Balance</Button>
          </CardContent>
        </Card>
      </CardsWrapper>
      <Modal
        isOpen={isShareModalActive}
        onClose={closeShareModal}
        title={'Share Referral Link with Friends'}
      >
        <ShareModalContent />
      </Modal>
      <Modal
        isOpen={isAirdropModalActive}
        onClose={closeAirdropModal}
        title={'Withdraw Airdrop Balance'}
      >
        <WithdrawBalanceContent />
      </Modal>
    </Wrapper>
  )
}

export default PersonalPercent