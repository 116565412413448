import React, { useState } from 'react'
import { Wrapper } from './styled'
import Staking from './Components/Staking'
import WalletConnect from './Components/WalletConnect'

const FirstLogin = () => {

  const [isWalletConnectActive, setIsWalletConnectActive] = useState<boolean>(false)
  const goToWalletConnect = () => {
    setIsWalletConnectActive(true)
  }

  return (
    <Wrapper>
      {isWalletConnectActive ?
        <WalletConnect onWalletConnect={() => setIsWalletConnectActive(false)} />
        :
        <Staking onActiveButtonClick={goToWalletConnect} />
      }
    </Wrapper>
  )
}

export default FirstLogin