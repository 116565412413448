import React, { useEffect } from 'react'
import {
  Container,
  Logo,
  HeaderItemsWrapper,
  HeaderItem,
  HeaderItemIcon,
  ConnectionCircle,
  MobileLogo,
  MobileMeniSticks,
  MenuSticksIcon,
} from './styled'
import LogoIcon from 'Assets/static/Logo.svg'
import MobileLogoIcon from 'Assets/static/MobileLogo.svg'
import USDTIcon from 'Assets/static/UsdtCoin.svg'
import GeccoIcon from 'Assets/static/GeccoCoin.svg'
import WalletIcon from 'Assets/static/Wallet.svg'
import Text from 'Components/UiKit/Text'
import { useTheme } from 'styled-components'
import { truncate } from 'Utils/common'
import { useWindowDimensions } from 'Hooks/useWindowDimensions'
import MenuSticks from 'Assets/icons/MenuSticks'
import { useWeb3React } from '@web3-react/core'
import { useBalanceOfUsdt, useBalanceOfGecco } from '../../Hooks/useTokenBalance'

const Header = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const { account } = useWeb3React()
  const { balance: usdtBalance, updateBalance: updateUsdtBalance } = useBalanceOfUsdt()
  const { balance: geccoBalance, updateBalance: updateGeccoBalance } = useBalanceOfGecco()

  useEffect(() => {
    updateUsdtBalance()
    updateGeccoBalance()
  }, [account])
  return (
    <Container>
      {width >= 940 ?
        <Logo src={LogoIcon} />
        :
        <MobileLogo src={MobileLogoIcon} />
      }
      {width >= 940 ?
        <HeaderItemsWrapper>
          <HeaderItem>
            <HeaderItemIcon src={GeccoIcon} />
            <Text size={16} weight={500} color={theme.color.text.secondary}>{(+geccoBalance / 10 **18).toFixed(1) || 0} Gecco</Text>
          </HeaderItem>
          <HeaderItem>
            <HeaderItemIcon src={USDTIcon} />
            <Text size={16} weight={500} color={theme.color.text.secondary}>{(+usdtBalance / 10 ** 18).toFixed(1) || 0} USDT</Text>
          </HeaderItem>
          {
            account &&
            <HeaderItem>
              <ConnectionCircle />
              <HeaderItemIcon src={WalletIcon} />
              <Text
                size={16}
                weight={500}
                color={theme.color.text.secondary}>
                {truncate(account)}
              </Text>
            </HeaderItem>
          }
        </HeaderItemsWrapper>
        :
        <MobileMeniSticks>
          <MenuSticksIcon>
            <MenuSticks />
          </MenuSticksIcon>
        </MobileMeniSticks>
      }
    </Container>
  )
}

export default Header