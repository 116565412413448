import React from 'react'
import { CustomSelect } from './styled'
import Arrow from 'Assets/icons/Arrow'
import Text from 'Components/UiKit/Text'
const Select = () => {
  return (
    <CustomSelect>
      <Text size={14} weight={600}>USDT</Text>
      <Arrow />
    </CustomSelect>
  )
}

export default Select