import Web3 from 'web3';
import {useWeb3React} from '@web3-react/core';
import getRpcUrl from 'Utils/rpcUrl'
import StakingGecco from 'Contracts/StakingGecco.json'
import GeccoCoin from 'Contracts/GeccoCoin.json'
import GeccoObtain from 'Contracts/GeccoObtain.json'
import Usdt from 'Contracts/Usdt.json'
import {
  getStakingGeccoContractAddress,
  getGeccoCoinAddress,
  getGeccoObtainContractAddress,
  getUsdtContractAddress
} from '../Utils/getAddresses'

const RPC_URL = getRpcUrl();
const httpProvider = new Web3.providers.HttpProvider(RPC_URL, {
  timeout: 10000,
});
export const useWeb3 = () => {
  const {library} = useWeb3React();
  let web3;
  if (library) {
    web3 = new Web3(library.currentProvider || httpProvider);
  } else {
    web3 = new Web3(httpProvider);
  }
  return web3;
};

export const useContract = (abi: any, address: string) => {
  const web3 = useWeb3();
  return new web3.eth.Contract(abi, address);
};

export const useGeccoStakingContract = () => {
  const abi = StakingGecco.abi;
  return useContract(abi, getStakingGeccoContractAddress());
};
export const useGeccoCoinContract = () => {
  const abi = GeccoCoin.abi
  return useContract(abi, getGeccoCoinAddress())
}
export const useGeccoObtainContract = () => {
  const abi = GeccoObtain.abi
  return useContract(abi, getGeccoObtainContractAddress())
}
export const useUsdtContract = () => {
  const abi = Usdt.abi
  return useContract(abi, getUsdtContractAddress())
}



