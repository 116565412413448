import React from 'react'

const MenuSticks = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 12H15M3 6H21M3 18H21' stroke='black' strokeWidth='2' strokeLinecap='round'
            strokeLinejoin='round' />
    </svg>
  )
}

export default MenuSticks