import { StyledVars } from './types'
export const theme: StyledVars = {
	color: {
		background: {
			main: '#F2F4F7',
			mainLight: '#F5F6F8',
			secondary: '#FFF',
			green: '#12B76A',
			lightGreen: '#ECFDF3',
			button: {
				main: '#D8F843',
				secondary: '#FFF',
			},
			border: {
				main: '#EAECF0',
				disabledSecondary: '#E7E5E4',
				error: '#F00'
			}
		},
		text: {
			main: '#18181B',
			secondary: '#57534E',
			white: '#fff',
			green: '#027A48',
			buttonSecondaryDisabled: '#D7D3D0',
			error: '#F00'
		},
	}
}

export type ThemeFragment = {
	bgColorPrimary: string,
}

export const primaryThemeFragment: ThemeFragment = {
	bgColorPrimary: '#F2F4F7',
}


